import React from 'react';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';

import '../../generic-page.scss';
import './guides.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { Col, Row } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';

const ZZZAgentsWEngines: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page zzz guide-zzz'} game="zzz">
      <ul className="breadcrumb">
        <li>
          <Link to="/zenless/">Zenless Zone Zero</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/zenless/guides/">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>W-Engines</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/zzz/categories/category_engine2.png"
            alt="Characters"
          />
        </div>
        <div className="page-details">
          <h1>W-Engines</h1>
          <h2>
            W-Engines system in Zenless Zone Zero explained - how the weapons
            work in this game.
          </h2>
          <p>
            Last updated: <strong>18/07/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <div className="page-content">
        <SectionHeader title="W-Engines" />
        <StaticImage src="../../../images/zzz/guides/engine.webp" alt="Guide" />
        <p>
          W-Engines are the Zenless Zone Zero equivalent of Weapons from Genshin
          Impact or Light Cones from Honkai: Star Rail. Here's some more
          information about the system:
        </p>
        <ul>
          <li>
            W-Engines come in 3 rarities:{' '}
            <strong className="s-rank">S-rank</strong>,{' '}
            <strong className="a-rank">A-rank</strong> and{' '}
            <strong className="b-rank">B-rank</strong>.
          </li>
          <li>
            W-Engines increase the Agent's Attack and another stat that changes
            based on the W-Engine,
          </li>
          <li>
            W-Engines also come with a passive effect that activates in the
            combat,
          </li>
          <ul>
            <li>
              W-Engines are restricted to the character's Specialty - so for
              example,{' '}
              <strong>
                only Attack characters can activate the special passive of an
                Attack W-Engine
              </strong>
              .
            </li>
          </ul>
          <li>You can level W-Engines in the same way as you level Agents,</li>
          <li>
            You can obtain W-Engines from the Signal Search, buy them in the
            Gadget Store or obtain them as random drops from some game modes.
          </li>
        </ul>
        <p>
          You can find the list of currently available W-Engines in this guide:
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="W-Engines"
            link="/zenless/w-engines"
            image={
              <StaticImage
                src="../../../images/zzz/categories/category_engines.png"
                alt="W-Engines"
              />
            }
          />
        </Row>
        <SectionHeader title="Best Battle Pass W-Engines" />
        <Row className="video">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="VE0o6fdztZQ" />
          </Col>
        </Row>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
    </DashboardLayout>
  );
};

export default ZZZAgentsWEngines;

export const Head: React.FC = () => (
  <Seo
    title="W-Engines | Zenless Zone Zero | Prydwen Institute"
    description="W-Engines system in Zenless Zone Zero explained - how the weapons work in this game."
    game="zzz"
  />
);
